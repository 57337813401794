@import url(https://fonts.bunny.net/css2?family=Nothing+You+Could+Do&family=Ubuntu:wght@300;700&display=swap);

$black: #000;
$white: #fff;
$light: #ebebeb;

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu";
  font-weight: 300;
  background: $light;
}

h1,
h2,
h3 {
  font-weight: 300;
}

.Header {
  position: fixed;
  background: $black;
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  color: $white;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  z-index: 99999999;
  .logo {
    height: 30px;
  }
  .phone {
    cursor: pointer;
    &:hover {
      transform: scale(1.05);
    }
    height: 30px;
    &.active {
      background: rgba(255, 255, 255, 0.5);
    }
  }
  .contact {
    position: relative;
    .contact-wrapper {
      color: $black;
      position: absolute;
      top: 100%;
      right: 0;
      width: 300px;
      max-width: 80vw;
      padding: 2rem;
      background: #ccc;
      p {
        margin: 0;
      }
      h2 {
        margin: 0;
      }
    }
  }
}

main.content {
  padding-top: 100px;
  padding-left: 4vw;
  padding-right: 4vw;
  box-sizing: border-box;
  width: 100%;
  padding-bottom: 4rem;
  min-height: 100vh;
}

.language {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  display: flex;
  margin-right: 2rem;
  justify-content: space-evenly;
  overflow: hidden;
  @media (max-width: 800px) {
    margin-right: 1rem;
  }
  .lang {
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0.5rem 1rem;
    width: 100%;
    @media (max-width: 800px) {
      margin-right: 0.25rem 0.5rem;
      font-size: 0.8rem;
    }
    &.active {
      background: rgba(255, 255, 255, 0.5);
      color: #fff;
      font-weight: bold;
    }
  }
}
