.Dashboard {
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;

    @media (max-width: 800px) {
      grid-template-columns: 1fr;
      > * {
        grid-column: span 1;
      }
    }

    .weather {
      grid-column: span 4;
      display: flex;
      height: 30vh;
      @media (max-width: 1200px) {
        grid-column: span 6;
      }
      > div {
        width: 100%;
      }
    }
    .dashboard-item {
      display: flex;
      grid-column: span 4;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
      height: 30vh;
      border-radius: 5px;
      overflow: hidden;
      padding: 2rem;
      box-sizing: border-box;
      color: #fff;
      font-weight: 300;
      font-size: 2em;
      text-decoration: none;
      align-items: flex-end;
      background-size: cover;
      position: relative;
      @media (max-width: 1200px) {
        grid-column: span 6;
      }
      transition: all 0.3s ease;
      .inner {
        position: relative;
        z-index: 10;
        font-weight: 300;
        .sub {
          font-size: 1rem;
        }
      }
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }
      &:hover {
        transition: all 0.3s ease;
        transform: translateY(2px);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
      }
    }
  }
}
