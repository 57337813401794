.glossary-grid {
  .glossary-item {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.1);
    line-height: 1.5;
    font-size: 1rem;
    .title {
      font-weight: bold;
      margin-bottom: 1rem;
      font-size: 1.5rem;
    }
    &:nth-child(odd) {
      background: rgba(0, 0, 0, 0.05);
    }
    .text {
      white-space: pre-wrap;
    }
  }
}

.glossary-index {
  display: grid;
  margin-bottom: 2rem;
  grid-gap: 0.25rem;
  grid-template-columns: repeat(auto-fit, minmax(min(40px, 100%), 1fr));
  > div {
    display: flex;
    padding: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-sizing: border-box;
    justify-content: center;

    cursor: pointer;
    align-items: center;
    .count {
      font-size: 0.7rem;
      margin-left: 0.5rem;
    }
    &.active {
      background: #000;
      color: #fff;
      font-weight: bold;
    }
    &.all {
      grid-column: span 2;
    }
  }
}
